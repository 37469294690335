import { useEffect } from "react";
// store
import useStore from "@services/store/store";
// socket.io
import { io } from "socket.io-client";

export default function Socket() {
  const { user, calls, setCalls } = useStore((state) => state);

  useEffect(() => {
    if (!user) return;
    const command = "prodOrderEvent";

    const url = process.env.REACT_APP_SOCKET_URL;

    const socket_client_id = user?.socket_client_id;
    console.log(user);

    const socket = io(`${url}?user_id=${socket_client_id}`);

    socket.on("connect", () => {
      socket.emit("joinRoom", `room:${socket_client_id}`);
    });

    socket.on("disconnect", (reason) => {});
    socket.on(`${command}:${socket_client_id}`, handleOrderEvent);

    // on error
    socket.on("error", (error) => {
      console.log("error: ", error);
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOrderEvent = (event) => {
    const res = event;

    if (res.command === "waiter_call") {
      const data = res?.data;

      // showAlert(
      //   `Вызов официанта на стол: ${data.data.table_number} (${data.data.place})`,
      //   {
      //     type: "info",
      //   }
      // );

      // const audio = new Audio(require("assets/alert.mp3"));
      // audio.volume = 1;
      // if (audio) {
      //   audio.play();
      // }

      const waiterLocal = JSON.parse(localStorage.getItem("waiterCall")) || [];

      // set to local storage
      localStorage.setItem(
        "waiterCall",
        JSON.stringify([...waiterLocal, data])
      );

      // set to store
      setCalls([...waiterLocal, data]);

      // alert("Вызов официанта на стол: " + data.data.table_number);
      // dispatch(
      // 	StoreActions.app.SetWaiterCall.request([
      // 		...waiterLocal,
      // 		data
      // 	])
      // );
    }
  };

  return <>{/* <h1 className="text-3xl font-bold">Socket</h1> */}</>;
}
