import React, { useEffect } from "react";
// notistack
import { SnackbarProvider } from "notistack";
// react-router-dom
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
// pages
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
// components
import Header from "./components/Header/Header";

function ProtectedRoute({ element }) {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return element;
}

function App() {

  return (
    <Router>
      <div className="flex flex-col m-auto">
        <SnackbarProvider />
        <Header />
        <Routes>
          <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
          <Route path="login" element={<AuthPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
