import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// axios
import axios from "axios";
// api
import { endpoints } from "@api/endpoints";
// notistack
import { enqueueSnackbar } from "notistack";
// react-router-dom
import { useLocation } from "react-router-dom";
// store
import useStore from "@services/store/store";
// helpers
import fetchData from "@helpers/fetchData";
// scss
import "./header.css";

export default function Header() {
  const navigate = useNavigate();

  const { company, user, setUser, orders, setOrders, setShowInvoice } =
    useStore((state) => state);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tableId = queryParams.get("table_id");

  async function getme(token) {
    try {
      const response = await fetchData({
        path: endpoints.user.getme,
        method: "GET",
        auth: true,
      });

      if (response && response.data) {
        setUser({
          ...response.data.success,
          token,
        });
        // Перенаправить на главную страницу
        navigate("/");
      } else {
        console.error("Login failed: No data received");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  }

  useEffect(() => {
    fetchOrders();

    const token = localStorage.getItem("token");

    if (token) {
      getme(token);
    }

    // eslint-disable-next-line
  }, []);

  // get current path last after /
  const path = location.pathname.split("/").pop();

  function logout() {
    setUser(null);
    localStorage.removeItem("token");
    navigate("/login");
  }

  // function to send api call
  async function callWaiter() {
    axios({
      url: `${process.env.REACT_APP_API_URL}table/waiter/call`,
      // body request
      data: {
        table_id: tableId,
        company_id: company?.id,
      },
      method: "POST",
    })
      .then((response) => {
        enqueueSnackbar("Официант вызван");
      })
      .catch(function (error) {
        enqueueSnackbar(error?.response?.data?.message);
      });
  }
  async function fetchOrders() {
    if (!tableId) return;
    axios({
      url: `${process.env.REACT_APP_API_URL}table/orders/${tableId}`,
      method: "POST",
    })
      .then((response) => {
        if (response?.data?.data) {
          setOrders(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {path === "qr-code" ? (
        <div></div>
      ) : (
        <div className="navbar bg-[#a5141a] py-[10px] rounded-b-[12px]">
          <div className="container">
            <div className="flex flex-row items-center justify-between">
              <div className="h-[44px] flex flex-col justify-center">
                <h3 className="text-white text-[20px] font-bold">
                  Zim-zim Waiter Call
                </h3>
              </div>
              <div>
                {/* logout button */}
                {user?.token && (
                  <button
                    className="bg-[#fff] text-[#a5141a] px-[20px] py-[10px] rounded-[12px] font-bold"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Выход
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
