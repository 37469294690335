// src/store.js
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useStore = create(
  devtools((set) => ({
    user: null,
    setUser: (user) => set({ user }),
    app: {
      lang: "en",
      theme: "light",
    },
    calls: [],
    setCalls: (calls) => set({ calls }),
    setLang: (lang) => set((state) => ({ app: { ...state.app, lang } })),
    setTheme: (theme) => set((state) => ({ app: { ...state.app, theme } })),
  }))
);

export default useStore;
