// store
import useStore from "@services/store/store";

export default function CallItem({ call }) {
  const { calls, setCalls } = useStore((state) => state);

  function acceptCall() {
    // remove call from local storage
    const calls = JSON.parse(localStorage.getItem("waiterCall")) || [];
    const newCalls = calls.filter((c) => c.id !== call.id);

    localStorage.setItem("waiterCall", JSON.stringify(newCalls));

    // set calls to store
    setCalls(newCalls);
  }

  return (
    <div className="call-list_item flex flex-col items-center justify-center">
      Стол № {call?.data?.table_number || "0"} -{" "}
      {call?.data?.place || "место не указано"}
      <div className="accept" onClick={() => acceptCall(call.id)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 50 50"
        >
          <path
            fill="#fff"
            d="M 42.964844 8.6894531 A 1.0001 1.0001 0 0 0 42.160156 9.1582031 L 21.699219 40.867188 L 7.671875 28.158203 A 1.0004048 1.0004048 0 1 0 6.328125 29.640625 L 21.228516 43.140625 A 1.0001 1.0001 0 0 0 22.740234 42.941406 L 43.839844 10.242188 A 1.0001 1.0001 0 0 0 42.964844 8.6894531 z"
          ></path>
        </svg>
      </div>
    </div>
  );
}
