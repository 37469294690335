import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
// store
import useStore from "@services/store/store";
// api
import { endpoints } from "@api/endpoints";
// helpers
import fetchData from "@helpers/fetchData";

export default function Auth() {
  const navigate = useNavigate();

  const { setUser } = useStore((state) => state);

  async function login(values) {
    try {
      const response = await fetchData({
        path: endpoints.user.login,
        method: "POST",
        data: values,
      });

      if (response && response.data) {
        console.log(response.data);

        // Сохранить токен в локальном хранилище
        localStorage.setItem("token", response.data.success.token);
        setUser({
          ...response.data.success.user,
          token: response.data.success.token,
        });
        // Перенаправить на главную страницу
        navigate("/");
      } else {
        console.error("Login failed: No data received");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center mt-[25vh]">
      {/* login and password form */}

      <h1 className="text-3xl font-bold mb-5">Login</h1>

      <Formik
        initialValues={{ login: "", password: "" }}
        validate={(values) => {
          const errors = {};

          if (!values.login) {
            errors.login = "Required";
          }

          if (!values.password) {
            errors.password = "Required";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          login(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex flex-col items-center justify-center">
              <div>
                <Field
                  type="text"
                  name="login"
                  placeholder="Login"
                  className="p-2 m-2 border-2 border-gray-300 rounded-md"
                />

                <ErrorMessage name="login" component="div" />
              </div>

              <div>
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="p-2 m-2 border-2 border-gray-300 rounded-md"
                />

                <ErrorMessage name="password" component="div" />
              </div>
              <button
                type="submit"
                className="px-[14px] py-[8px] m-2 bg-blue-500 text-white rounded-md"
              >
                Login
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
